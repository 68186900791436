import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  
  constructor(
    private http : HttpClient,
    private auth : AuthService
    ) { }


  getAllSuppliers(searchParams?){
    return this.http.get(`${environment.base_url}v1/supplier`,{params:searchParams});
  }

  getSupplier(id){
    return this.http.get(`${environment.base_url}v1/supplier/`+id);
  }

  createSupplier(form : FormGroup){
    return this.http.post(`${environment.base_url}v1/supplier`,form.getRawValue());
  }
  
  deleteSupplier(supplierId){
    return this.http.delete(`${environment.base_url}v1/supplier/`+supplierId);
  }

  editSupplier(form : FormGroup, id){
    return this.http.put(`${environment.base_url}v1/supplier/`+id,form.getRawValue());
  }



}
