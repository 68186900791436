export class Customer {
    id : number;
    first_name: string;
    last_name: string;
    phone_number: number;
    mobile_number: number;
    email: string;
    company_name: string;
    vat_no?: number;
    invoice_email : string;
    address_1: string;
    address_2: string;
    post_code: string;
    location: string;
    full_name: string;
    reference:string;


    constructor(input?) {
        this.id = input ? input.id : "";
        this.first_name = input ? input.first_name : "";
        this.last_name = input ? input.last_name : "";
        this.phone_number = input ? input.phone_number : null;
        this.mobile_number = input ? input.mobile_number : null;
        this.email = input ? input.email : null;
        this.company_name = input ? input.company_name : null;
        this.vat_no = input ? input.vat_no : null;
        this.invoice_email = input ? input.invoice_email : null;
        this.address_1 = input ? input.address_1 : null;
        this.address_2 = input ? input.address_2 : null;
        this.post_code = input ? input.post_code : null;
        this.location = input ? input.location : null;
        this.full_name = this.first_name+" "+this.last_name;
        this.reference = input ? input.reference : null;
    }
}