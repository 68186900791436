import { Developer } from "./developer.model";

export class Project {
	id: string;
	title: string;
	address_1: string;
	address_2: string;
	post_code: string;
	location?: string;
	developer?: Developer;
	main_door_budget: number;
	external_door_budget: number;
	internal_door_budget: number;

	constructor(input?) {
		input = input || {};
		this.id = input.id || null;
		this.title = input.title || '';
		this.address_1 = input.address_1 || '';
		this.address_2 = input.address_2 || '';
		this.post_code = input.post_code || '';
		this.location = input.location || '';
		this.developer = new Developer(input.developer) || null;
		this.main_door_budget = input.main_door_budget || 0;
		this.external_door_budget = input.external_door_budget || 0;
		this.internal_door_budget = input.internal_door_budget || 0;
	}

}