export class Developer {
	id?: string;
	company_name?: string;
	vat_no?: string;
	email?: string;
	invoice_email?: string;
	address_1: string;
	address_2: string;
	post_code: string;
	location: {
	  value: number,
	  text: string
	};
	contact_person: string;
	contact_person_email: string;
	contact_person_phone: string;
	phone_number: string;
	phone_mobile: string;
  
	constructor(input?) {
	  input = input || {};
	  this.id = input.id || '';
	  this.email = input.email || '';
	  this.address_1 = input.address_1 || '';
	  this.address_2 = input.address_2 || '';
	  this.post_code = input.post_code || '';
	  this.location = input.location || '';
	  this.phone_number = input.phone_number || '';
	  this.phone_mobile = input.phone_mobile || '';
	  this.contact_person = input.contact_person || '';
	  this.contact_person_email = input.contact_person_email || '';
	  this.contact_person_phone = input.contact_person_phone || '';
	  this.company_name = input.company_name || '';
	  this.invoice_email = input.invoice_email || '';
	  this.vat_no = input.vat_no || '';
	}
  }
  