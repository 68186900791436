import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Developer } from '../models/developer.model';

@Injectable({
  providedIn: 'root'
})
export class DeveloperService {

  constructor(
    private http: HttpClient
  ) { }

  create(developer) {
    //console.log(developer);
    return this.http.post<Developer>(`${environment.base_url}v1/developer`, developer);
  }

  update(id, developer) {
    //console.log(developer);
    return this.http.put<Developer>(`${environment.base_url}v1/developer/${id}`, developer);
  }

  getById(id) {
    return this.http.get<Developer>(`${environment.base_url}v1/developer/${id}`);
  }

  getAll(queryParams?) {
    return this.http.get(`${environment.base_url}v1/developer`,{params:queryParams})
      .pipe(
        map(responseData => {
          //console.log(responseData);
          const postsArray: Developer[] = [];

          for (const key in responseData['data']) {
            if (responseData['data'].hasOwnProperty(key)) {
              postsArray.push({ ...responseData['data'][key], web_id: key });
              //console.log(responseData['data'][key]);
            }
          }
          return postsArray;

        }))
  }
}
