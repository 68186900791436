import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Door } from '../models/door.model';
import { DoorExtra } from '../models/door-extra.model';
import { AvailableDoorExtra } from '../models/available-door-extra.model';

@Injectable({
  providedIn: 'root'
})
export class DoorExtraService {

  constructor(
    private http: HttpClient
  ) { }

  create(extra) {
    return this.http.post<DoorExtra>(`${environment.base_url}v1/door/extra/list`, extra);
  }

  update(id, extra) {
    return this.http.put<DoorExtra>(`${environment.base_url}v1/door/extra/list/${id}`, extra);
  }

  delete(id) {
    return this.http.delete<DoorExtra>(`${environment.base_url}v1/door/extra/list/${id}`);
  }

  getById(id) {
    return this.http.get<DoorExtra>(`${environment.base_url}v1/door/extra/list/${id}`);
  }

  getAllExtraNoColor() {
    let param:any = {color:0};
    return this.http.get(`${environment.base_url}v1/door/extra/nocolor`,{params:param})
  }

  getAllExtras(){
    return this.http.get(`${environment.base_url}v1/door/extra/list`);
  }

  postExtraImage(image){
    const formData = new FormData();
    formData.append('image', image);
    console.log(formData);
    return this.http.post<any>(`${environment.base_url}v1/door/extra/list/upload`, formData);
  }

  getExtraTypes(){
    return this.http.get(`${environment.base_url}v1/door/extra/type`);
  }

  setExtras(doorId,extras){
    return this.http.put(`${environment.base_url}v1/door/`+doorId+`/extras`,{extras:extras});
  }

  getByCategory(id) {
    return this.http.get(`${environment.base_url}v1/door/extra/category/${id}/list`)
      .pipe(
        map(responseData => {
          //console.log(responseData);
          const postsArray: DoorExtra[] = [];

          responseData['data'].forEach((e) => {
            postsArray.push(new DoorExtra(e));
          })

          return postsArray;

        }))
  }



}
